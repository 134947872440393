import Shipments from './Shipments';
import AwaitingShipments from './AwaitingShipments';

type Props = { 
  order: any,
  store: string
};

const Order: React.FC<Props> = (props) => {
  return (
    <main className="order-container">
      <div className="container">
        <header className="order-header">
          <div className="title-wrapper"><span className="order">Order #{props.order.id} Status</span>
          
          </div>
          <div className="text-center status-wrapper">
            <div className={"themed-grid-col status-" + props.order.status_id}>{props.order.status}</div>
          </div>
          <div className="date-wrapper"><strong>Order Date:</strong> {props.order.date}</div> 
        </header>
        {props.order.awaiting_shipments.length > 0 && <AwaitingShipments shipments={props.order.awaiting_shipments} />}
        {props.order.shipments.map((shipment: any) => <Shipments shipment={shipment} store={props.store} />	)}
      </div>
    </main>
  );
}

export default Order;
