import Item from './Item';

type Props = { 
  shipment: any,
  store: string
};

const Shipments: React.FC<Props> = (props) => {
  return (
    <div>
      <h1 className="mt-5">Shipment #{props.shipment.index}</h1>
      <hr />
      <p><strong>Ship Date:</strong> {props.shipment.date}</p>
      <p><strong>Shipping Method:</strong> {props.shipment.shipping_method}</p>
      <p><strong>Tracking Number:</strong> <a className="tracking-number" href={"http://track."+(props.store === "psp" ? "playsetparts" : "swingsetmall") +".com/" + props.shipment.tracking_number} target="_blank" rel="noreferrer">{props.shipment.tracking_number}</a></p>
      <div className="row  table-header">
        <div className="col-8 text-center header"><strong>Item{props.shipment.items.length > 1 ? "s" : ""}</strong></div>
        <div className="col-4 text-center header hide-size-mobile"><strong>Qty Shipped</strong></div>
      </div>
      {props.shipment.items.map((item: any) => <Item item={item} shipped={true} />)}
    </div>
  );
}

export default Shipments;
