import { useState } from "react";
import Item from './Item';

type Props = {
  shipments: any
};

const AwaitingShipments: React.FC<Props> = (props) => {

  const [tooltip, setTooltip] = useState(false)

  return (
    <div className="awaiting-shipment-container">
      <h1>Awaiting Shipment</h1>
      <hr />
      <p>The following item(s) from your order are awaiting shipment. Please see the estimated ship dates.</p>
      <div className="row mb-3  table-header">
        <div className="col-8 text-center header"><strong>Item{props.shipments.length > 1 ? "s" : ""}</strong></div>
        <div className="col-4 text-center header hide-size-mobile">
          <div className="tooltip__wrapper" onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)} onClick={(e) => { e.preventDefault(); setTooltip(!tooltip) }} >
            <strong>Est. Ship Date</strong>
            &nbsp;



            {tooltip && <div
              className="est-tooltip"
              id={`tooltip-top`}
            >
              The following dates are provided as an estimate for when your items will ship from the warehouse. Once shipped, items usually take between 2-7 business days to deliver.
            </div>}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 20 20">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
        </div>
      </div>
      {props.shipments.map((item: any, index: any) => <Item key={index} item={item} shipped={false} />)}
    </div>
  );
}

export default AwaitingShipments;
