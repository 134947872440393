type Props = { 
  item: any,
  shipped: boolean
};

const Item: React.FC<Props> = (props) => {

  var thumb = <img src='' width="110" height="110" alt=""/>
  if (props.item.url_thumbnail !== '') {
	  thumb = <img src={props.item.url_thumbnail} width="110" height="110" alt="" />
  }

  console.log({item: props.item})

  return (
    <div className="item">
      <div className="item-image">
        {thumb}
      </div>
      <div className="item-info">
        <div className="item-details">
          <a href={props.item.url} target="_blank" rel="noreferrer">{props.item.name}</a>
          <ul>
          {props.item.options.map((option: any, index: any) => <li key={index}><strong>{option.split(":")[0]}:</strong> {option.split(":")[1]}</li>)}
          </ul>
        </div>

        <div className={props.shipped ? "item-qty" : "est-ship-date" + " column-value"}>{props.item.value}</div>
      </div>
    </div>
  );
}

export default Item;
