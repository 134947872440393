import { useState, useEffect } from "react";

type Props = { 
  onLogin(obj: any): void, 
  store: string, 
};

const Login: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [order, setOrder] = useState('');
	const [error, setError] = useState('');
	const [store, _] = useState(props.store);

  useEffect(() => {
    const emailParam = getUrlParameter('email');
    const orderParam = getUrlParameter('order');
    const loadingParam = getUrlParameter('loading')
    if (emailParam !== '' && orderParam !== '') {
      setEmail(emailParam);
      setOrder(orderParam);
      login(emailParam, orderParam, store);
    };
    if(loadingParam){
      setLoading(true)
    }

    console.log(window.location.host)
  }, []);


  const getUrlParameter = (sParam: string): string => {
    var sPageURL = decodeURIComponent(window.location.search.substring(1));
    var sURLVariables = sPageURL.split('&');
    var sParameterName;
    var i;
    var res: string;

    res = '';
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            res = sParameterName[1] === undefined ? '' : sParameterName[1];
            break;
        }
    }
    return res;
  }

  const onEmailChange = async (event: any) => {
    setEmail(event.target.value);
  }

  const onOrderChange = async (event: any) => {
    setOrder(event.target.value);
  }

	const onLogin = async () => {
    login(email, order, store);
  };

	const login = (emailParam: string, orderParam: string, store: string) => {
    if (emailParam === '') {
      setError('Email is required.');
      return;
    }
    if (orderParam === '') {
      setError('Order ID is required.');
      return;
    }

    setLoading(true);
    var url = process.env.REACT_APP_SERVER_URL + "/check-order.php?email=" + emailParam  + "&order=" + orderParam + (store ? "&store=" + store : "");
    fetch(url, {
			method: 'GET',
			headers:  new Headers({
				'Content-Type': 'application/json',
				"Accept":"application/json",
				}
			),
		})
		.then(res => res.json())
		.then(
			(response) => {
				// console.log(response);
        setLoading(false);
        if (response.result) {
          setError("");
          props.onLogin(response.order);
        } else {
          setError(response.message);
        }
			},
			(error) => {
				// console.log(error);
				setError(error.message);
        setLoading(false);
			}
		)
  };

  return (
    <div className="text-center form-signin">
      {loading &&
        <div className="loader" id="loader">
          <div className="loader__holder">
            <h3>Loading...</h3>
            <div className="cover-spin"></div>
          </div>
        </div>
      } 
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header py-3">
          <h4 className="my-0 fw-normal"><strong>Order Status Lookup</strong></h4>
        </div>
        <div className="card-body">
          <form id="login-form" action="order.php">
            <div className="form-floating">
              <input type="email" className="form-control" name="email" id="email" placeholder="Email Address" value={email} onChange={onEmailChange}/>
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="form-floating">
              <input type="text" className="form-control" name="order" id="order" placeholder="Order ID#"  value={order} onChange={onOrderChange}/>
              <label htmlFor="order">Order ID#</label>
            </div>
          </form>
          <div className="form-floating">
            <button className="w-100 btn btn-lg" id="login-button" onClick={onLogin}>Track Order</button>
            {error !== '' &&
            <p id="login-error">{error}</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
