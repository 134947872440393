import './App.scss';
import { useState } from "react";
import Login from './Login';
import Order from './Order';

function App() {
  const [logged, setLogged] = useState(false);
  const [order, setOrder] = useState<any>({});

  let store
  if (window.location.hostname.includes("127.0.0.1")) {
    store = "ssm";
  } else if (window.location.hostname.includes("localhost")) {
    store = "psp";
  } else if (window.location.hostname.includes("swingsetmall.com")) {
    store = "ssm";
  } else if (window.location.hostname.includes("playsetparts.com")) {
    store = "psp";
  } else {
    store = "ssm";
  }

  const onLogin = async (obj: any) => {
    // console.log(obj);
    setOrder(obj);
    setLogged(true);
  };

  return (
    <div className={store}>
      {!logged &&
        <Login onLogin={onLogin} store={store} />
      }
      {logged &&
        <Order order={order} store={store} />
      }
    </div>
  );
}

export default App;
